<template>
  <div>
    <Loader :loading="loading" />    
    <div class="d-flex justify-space-between">
      <div style="width: 150px;">
        <v-select
          v-model="perPage"
          label="Total visible"
          :items="listPerPage"
          @change="resetCurrentPage"
          dense
        />
      </div>
      <div>
        <v-btn
          @click="setCustomer"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t('refresh') }}
        </v-btn>
        <v-btn
          @click="createCustomer"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('customer') }}
        </v-btn>
        <!-- <v-btn
          @click="addProductByCsv"
          color="success"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          csv
        </v-btn> -->
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="entities"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              @click="editClient(item)"
            >
              <v-icon left>mdi-pencil</v-icon>
              <small>Editar</small>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.address="{ item }">
        <div v-if="item.addresses.length > 0">
          {{ getAddress(item.addresses) }}
          <v-icon
            v-if="item.addresses[0].location_url"
            link
            small
            @click="openUrl(item.addresses[0].location_url)"
            color="success"
          >
            mdi-store-marker-outline
          </v-icon>
        </div>
      </template>
      <template v-slot:item.phone="{ item }">
        <div v-if="item.contacts.length > 0">
          {{ item.contacts[0].phone1 }}
        </div>
      </template>
      <template v-slot:item.email="{ item }">
        <div v-if="item.contacts.length > 0">
          {{ item.contacts[0].email1 }}
        </div>
      </template>
      <template v-slot:item.notes="{ item }">
        <div v-if="item.contacts.length > 0">
          {{ item.contacts[0].notes }}
        </div>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
    <CustomerCreate
      ref="customerCreate"
      @onSendEntity="onGetEntity"
    />
  </div>
</template>

<script>
// import EntityCreate from './EntityCreate'
import CustomerCreate from '@/components/commons/customer/CustomerCreate';
import { mapGetters } from 'vuex';
import EntityServices from '@/store/services/general/EntityServices';
import ListMixin from '@/mixins/commons/ListMixin';
export default {
  components: {
    CustomerCreate,
    // EntityCreate
  },
  mixins: [ListMixin],
  data() {
    return {
      loading: false,
      entities: [],
    }
  },
  methods: {
    getAddress(addresses) {
      const address = addresses[0];
      const zoneName = address?.zone?.name ?? 'S/Z';
      return `${address.country}, ${address.state}, ${zoneName}`;
    },
    editClient(item) {
      this.$refs['customerCreate'].dialog = true;
      this.$refs['customerCreate'].entityId = item.id;
      this.$refs['customerCreate'].contactId = item.contacts[0].id;

      this.$refs['customerCreate'].name = item.name;
      this.$refs['customerCreate'].nit = item.nit;
      this.$refs['customerCreate'].contact = Object.assign({}, item.contacts[0]);
      if (item.addresses.length > 0) {
        this.$refs['customerCreate'].showMoreInfo = true;
        this.$refs['customerCreate'].addressId = item.addresses[0].id;
        this.$refs['customerCreate'].address = Object.assign({}, item.addresses[0]);
        this.$refs['customerCreate'].setZoneId(item.addresses[0].zone_id);
      }

    },
    async setCustomer() {
      try {
        this.loading = true;
        const params = {
          order_by: 'name',
          order_direction: 'ASC',
          page: 1,
          per_page: this.perPage,
          include: 'contacts,addresses.zone',
          search_type: 'CUSTOMER',
          // models: ['entity']
        };
        const response = await EntityServices.index(
          this.user.archon.id,
          params
        )
        this.entities = response.data.data
        this.currentPage = response.data.current_page
        this.lastPage = response.data.last_page
        this.total = response.data.total
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    resetCurrentPage() {
      if (this.currentPage === 1) this.setCustomer();
      else this.currentPage = 1;
    },
    createCustomer() {
      this.$refs['customerCreate'].dialog = true;
    },
    onGetEntity(data) {
      if (data.type === 'store') this.entities.unshift(data.entity);
      else {
        const index = this.entities.findIndex(entity => entity.id === data.entity.id);
        this.entities.splice(index, 1, data.entity);
      }
    },
    openUrl(value) {
      window.open(value, '_blank');
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'NIT',
          sortable: false,
          value: 'nit',
        },
        {
          text: 'Razon Social',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('address'),
          sortable: false,
          value: 'address',
        },
        {
          text: 'Teléfono',
          sortable: false,
          value: 'phone',
        },
        {
          text: 'Email',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Notes',
          sortable: false,
          value: 'notes',
        },
      ];
    },
  },
  created() {
    this.setCustomer();
  },
}
</script>

<style>

</style>